import React from 'react'
import { Link } from 'gatsby'
import { WrapperSection } from '../../wrappers'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { s, colors, alpha } from '../../style'
import { T } from '../../typo'

const AboutMaps = ({ data, theme }) => {
  const { slug_section, title, countries } = data
  const cities = countries || ['Gdynia', 'Gaj koło Wyszkowa', 'Kraków']
  return (
    <WrapperSection
      id={slug_section}
      extraCss={{
        [s.md]: { marginTop: 0 },
        [s.sm_down]: { padding: '0 1.5rem' },
        position: 'relative',
      }}>
      <T
        xs={20}
        sm={24}
        md={28}
        lg={32}
        xl={32}
        center
        upper
        bold
        spacious
        variant="h2"
        extraCss={{ color: colors.main, paddingTop: '4rem' }}>
        {title}
      </T>
      <div css={sCities}>
        {cities?.map((city, id) => (
          <Link to="/pl/kontakt" css={sCity} key={id}>
            {city}
          </Link>
        ))}
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          [s.md_down]: { flexDirection: 'column' },
        }}>
        <Map1 />
        <div css={{ marginBottom: '2rem' }} />
        <Map2 />
      </div>
    </WrapperSection>
  )
}

const sCities = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '2rem 0 4rem',
  flexWrap: 'wrap',
}

const sCity = {
  padding: '4px 8px',
  backgroundColor: colors.main.concat(alpha[8]),
  color: colors.main.concat(alpha[60]),
  fontSize: '1rem',
  marginRight: '1rem',
  marginBottom: '1rem',
  borderRadius: '1.5rem',
  fontWeight: 700,
}

const Map1 = () => {
  const center = [
    {
      lat: 54.52222,
      lng: 18.54381,
    },
    {
      lat: 50.06143,
      lng: 19.93658,
    },
    {
      lat: 52.5224717,
      lng: 21.4054024,
    },
    // {
    //   lat: 50.0413600766602,
    //   lng: 21.99901629994135,
    // },
  ]
  const polCenter = {
    lat: 52.044925000000006,
    lng: 19.069815000000002,
  }
  // const krakow = {
  //   lat: 50.06143,
  //   lng: 19.93658,
  // }
  // const warszawa = {
  //   lat: 52.22977,
  //   lng: 21.01178,
  // }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCj7tuwGIRrFd_7RCaJN6rFwWzpR4KLbV0',
  })
  /* eslint-disable */
  const [map, setMap] = React.useState(null)
  /* eslint-enable */
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle1}
      center={polCenter}
      zoom={5.5}
      // Working without this prop
      // onLoad={onLoad}
      onUnmount={onUnmount}
      defaultOptions={{ mapTypeControl: false }}>
      {center.map((mark, id) => (
        <Marker key={id} position={mark} />
      ))}
    </GoogleMap>
  ) : (
    <CircularProgress />
  )
}
const Map2 = () => {
  const center = {
    lat: 54.52222,
    lng: 18.54381,
  }
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCj7tuwGIRrFd_7RCaJN6rFwWzpR4KLbV0',
  })
  /* eslint-disable */
  const [map, setMap] = React.useState(null)
  /* eslint-enable */
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle1}
      center={center}
      zoom={13.5}
      defaultZoom={14}
      defaultCenter={center}
      // Working without this prop
      // onLoad={onLoad}
      onUnmount={onUnmount}
      panTo={center}
      defaultOptions={{ mapTypeControl: false }}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <CircularProgress />
  )
}

const containerStyle1 = {
  width: 600,
  height: 600,
  maxWidth: '100vw',
  maxHeight: '100vw',
  borderRadius: '50%',
  gap: '2rem',
  button: {
    display: 'none !important',
  },
}

const containerStyle2 = {
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 300,
  height: 300,
  borderRadius: '50%',
  '@media screen and (max-width: 599px)': {
    width: 'calc(100vw-6rem)',
    height: 'calc(100vw-6rem)',
  },
}

export default AboutMaps
